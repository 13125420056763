import { Heading, Paragraph } from '@/components/dom/text-elements';
import { CardQuickView } from '@/components/research/card-quick-view';
import cn from '@/lib/cn';
import { ResearchNote } from '@/queries/graphql-types';

interface UserResearchItemGroupProps {
    id?: string;
    name: string;
    researchNotes: Array<ResearchNote>;
    className?: string;
}

export const UserResearchItemGroup = ({ id, className, name, researchNotes }: UserResearchItemGroupProps) => {
    return (
        <div
            id={id}
            className={cn(
                'flex flex-col gap-6 flex-shrink-0 w-full max-w-96 h-full max-h-[70vh] overflow-scroll no-scrollbar relative',
                className
            )}
        >
            <header className="sticky top-0 left-0 bg-white z-10 border-analyst-dark-lavender border-b pb-4">
                <Heading importance={5}>{name}</Heading>
            </header>

            <div className="flex flex-col gap-4">
                {researchNotes.map((researchNote, index: number) => (
                    <CardQuickView
                        key={`user-research-notes-${name}-${index}`}
                        researchData={researchNote}
                    />
                ))}
                {researchNotes.length < 1 && (
                    <Paragraph className="text-analyst-gray font-brand-md">No research notes found.</Paragraph>
                )}
            </div>
        </div>
    );
};
