'use client';

import { useEffect, useReducer, useRef, useState } from 'react';

import { FieldError, Label, TextArea, TextInput } from '@/components/dom/form-elements';
import { Paragraph } from '@/components/dom/text-elements';
import Button from '@/components/global/button';
import { FeaturedButton } from '@/components/global/featured-button';
import { NavigationLink } from '@/components/global/link';
import { ResearchNoteState, useResearch } from '@/components/providers/Research';
import { usePosthogTracking } from '@/helpers/hooks/usePosthogTracking';
import { getResearchNoteCategory, getResearchNoteTitleAndPageName } from '@/helpers/research';
import cn from '@/lib/cn';
import { AnalystThemeResult, ResearchNote } from '@/queries/graphql-types';
import { getTopLevelAnalysisResult } from '@/services/analysis';
import { type ResearchTrackingProperties } from '@/types/tracking';

export interface AddEditResearchItemFormProps {
    className?: string;
}

enum ReducerActions {
    UPDATE_TITLE = 'UPDATE_TITLE',
    UPDATE_NOTES = 'UPDATE_NOTES',
}

interface ReducerAction {
    type: ReducerActions;
    payload: string;
}

const reducer = (state: ResearchNoteState, action: ReducerAction): ResearchNoteState => {
    if (!state) return state;

    switch (action.type) {
        case ReducerActions.UPDATE_TITLE:
            return {
                ...state,
                organizationId: state.organizationId,
                title: action.payload,
                userId: state.userId,
            };
        case ReducerActions.UPDATE_NOTES:
            return {
                ...state,
                note: action.payload,
                organizationId: state.organizationId,
                userId: state.userId,
            };
        default:
            return state;
    }
};

export const AddEditResearchItemForm = ({ className }: AddEditResearchItemFormProps) => {
    const titleRef = useRef<HTMLInputElement>(null);
    const { eventTypes, trackManualEvent } = usePosthogTracking();
    const {
        currentResearchItem,
        saveResearchItem,
        updateSavedResearchItem,
        hideResearchSidebar,
        resetCurrentResearchItem,
        switchToReadMode,
    } = useResearch();
    const isUpdatingItem = !!(currentResearchItem as ResearchNote)?.id;
    const [researchItemData, updateResearchItem] = useReducer(reducer, currentResearchItem as ResearchNoteState);
    const [titleError, setTitleError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [currentPageUrl, setCurrentPageUrl] = useState('');
    const updateTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
        updateResearchItem({ payload: e.target.value, type: ReducerActions.UPDATE_TITLE });
    };
    const updateNotes = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        updateResearchItem({ payload: e.target.value, type: ReducerActions.UPDATE_NOTES });
    };
    const submitButtonCopy = isUpdatingItem ? 'Update' : 'Save';
    const researchItem = researchItemData as ResearchNote;
    const trackingProperties: ResearchTrackingProperties = {
        ...researchItem,
        exchange: researchItem?.instrument?.exchange || researchItem?.evaluation?.instrument?.exchange,
        indexName: researchItem?.index?.name,
        indexSymbol: researchItem?.index?.symbol,
        ticker: researchItem?.instrument?.symbol || researchItem?.evaluation?.instrument?.symbol,
    };
    const submitAction = async () => {
        if (!researchItemData?.title) {
            setTitleError('Title is required');
            return;
        }

        setIsSubmitting(true);

        const analysisResult =
            researchItem.analystRequestId && (await getTopLevelAnalysisResult(researchItem.analystRequestId));

        if (isUpdatingItem) {
            const category = getResearchNoteCategory(researchItem);
            const { pageName } = getResearchNoteTitleAndPageName({
                analystThemeField: researchItem.analystThemeField,
                category,
                company:
                    researchItem.instrument || researchItem.evaluation?.instrument
                        ? {
                              companyName:
                                  researchItem?.instrument?.companyName ||
                                  researchItem.evaluation?.instrument.companyName ||
                                  '',
                              exchange:
                                  researchItem?.instrument?.exchange ||
                                  researchItem.evaluation?.instrument.exchange ||
                                  '',
                              symbol:
                                  researchItem?.instrument?.symbol || researchItem.evaluation?.instrument.symbol || '',
                          }
                        : null,
                evaluationField: researchItem.evaluationField,
                instrumentField: researchItem.instrumentField,
                isEvaluation: !!researchItem.evaluationRequestId,
                symbol: researchItem.index?.symbol,
                theme: (researchItem as ResearchNoteState)?.theme,
            });
            await updateSavedResearchItem({
                category,
                note: researchItem.note,
                pageName,
                researchNoteId: researchItem.id,
                title: researchItem.title,
            });

            trackManualEvent({
                eventType: eventTypes.UPDATED_RESEARCH_ITEM,
                trackingProperties: {
                    ...trackingProperties,
                    theme: (analysisResult as AnalystThemeResult)?.theme,
                },
            });
        } else {
            await saveResearchItem(researchItemData);

            trackManualEvent({
                eventType: eventTypes.SAVED_NEW_RESEARCH_ITEM,
                trackingProperties: {
                    ...trackingProperties,
                    theme: (researchItem as ResearchNoteState)?.theme || (analysisResult as AnalystThemeResult)?.theme,
                },
            });
        }

        setIsSubmitting(false);
    };
    const cancelAction = () => {
        if (isUpdatingItem) {
            switchToReadMode();
        } else {
            hideResearchSidebar();
            resetCurrentResearchItem();
        }
    };

    useEffect(() => {
        setCurrentPageUrl(window.location.href);
    }, []);

    useEffect(() => {
        if (titleRef.current) {
            titleRef.current.scrollTo(0, 0);
            titleRef.current.setSelectionRange(0, 0);
        }
    }, []);

    return (
        <form className={cn('flex flex-col gap-8', className)}>
            <div>
                <Label
                    text="Title"
                    labelCopyClassName="text-left text-analyst-darker-gray font-brand-bold mb-2 text-base"
                >
                    <TextInput
                        ref={titleRef}
                        className="w-full"
                        fieldClassName="w-full rounded-full px-3 py-2"
                        value={researchItemData?.title || ''}
                        onChange={updateTitle}
                        required={true}
                        spellCheck={false}
                        placeholder="Enter title for research note"
                        autoFocus={true}
                        error={titleError}
                    />
                </Label>
                <FieldError message={titleError} />
            </div>

            <div>
                <Paragraph className="font-brand-bold mb-2">Page source link</Paragraph>

                {currentPageUrl && (
                    <NavigationLink
                        href={currentPageUrl}
                        className="text-analyst-blue font-brand-md"
                    >
                        {researchItemData?.pageName}
                    </NavigationLink>
                )}
            </div>

            <div>
                <Label
                    text="Notes"
                    labelCopyClassName="text-left text-analyst-darker-gray font-brand-bold mb-2 text-base"
                >
                    <TextArea
                        className="w-full"
                        fieldClassName="w-full rounded-md px-3 py-2 placeholder:italic placeholder:font-brand-base"
                        value={researchItemData?.note || ''}
                        onChange={updateNotes}
                        required={true}
                        spellCheck={false}
                        placeholder="Summarize key insights, highlight important details, or jot down notes for future reference..."
                        autoFocus={true}
                    />
                </Label>
                <FieldError message={titleError} />
            </div>

            <footer className="flex items-center justify-between gap-4 mt-6">
                <Button
                    type="action"
                    color="analyst-purple-wire"
                    roundedCorners="full"
                    onClick={cancelAction}
                    className="flex-grow font-brand-md"
                >
                    Cancel
                </Button>
                <FeaturedButton
                    onClick={submitAction}
                    className="flex-grow"
                    isDisabled={isSubmitting}
                >
                    {submitButtonCopy}
                </FeaturedButton>
            </footer>
        </form>
    );
};
