import { useCallback, useMemo, useState } from 'react';

import { Heading, Paragraph } from '@/components/dom/text-elements';
import Icon from '@/components/global/icon';
import { ActionLink, NavigationLink } from '@/components/global/link';
import ShareModal from '@/components/global/share-modal';
import { useLoggedInGate } from '@/components/providers/LoggedInGate';
import { ResearchNoteType, useResearch } from '@/components/providers/Research';
import { FormatUtcDate } from '@/helpers/dates';
import { usePosthogTracking } from '@/helpers/hooks/usePosthogTracking';
import { getResearchNoteShareUrl } from '@/helpers/research';
import { isUserOrganizationAdmin } from '@/helpers/user';
import { AnalystThemeResult } from '@/queries/graphql-types';
import { getTopLevelAnalysisResult } from '@/services/analysis';
import { type ResearchTrackingProperties } from '@/types/tracking';

interface ResearchDetailViewProps {
    className?: string;
}

export const ResearchDetailView = ({ className }: ResearchDetailViewProps) => {
    const { currentUser } = useLoggedInGate();
    const { eventTypes, trackManualEvent } = usePosthogTracking();
    const { currentResearchItem, editResearchItem, removeResearchItem } = useResearch();
    const [isShareModalOpen, setIsShareModalOpenState] = useState<boolean>(false);
    const canEdit =
        currentUser && (currentUser.id === currentResearchItem?.userId || isUserOrganizationAdmin(currentUser));
    const researchItem = currentResearchItem as ResearchNoteType;
    const trackingProperties = useMemo(
        () => ({
            ...researchItem,
            exchange: researchItem?.instrument?.exchange || researchItem?.evaluation?.instrument?.exchange,
            indexName: researchItem?.index?.name,
            indexSymbol: researchItem?.index?.symbol,
            ticker: researchItem?.instrument?.symbol || researchItem?.evaluation?.instrument?.symbol,
        }),
        [researchItem]
    );
    const editAction = useCallback(async () => {
        editResearchItem(researchItem);
        const analysisResult =
            researchItem.analystRequestId && (await getTopLevelAnalysisResult(researchItem.analystRequestId));
        trackManualEvent({
            eventType: eventTypes.EDIT_RESEARCH_ITEM,
            trackingProperties: {
                ...trackingProperties,
                theme: (analysisResult as AnalystThemeResult)?.theme,
            } as ResearchTrackingProperties,
        });
    }, [editResearchItem, eventTypes.EDIT_RESEARCH_ITEM, researchItem, trackManualEvent, trackingProperties]);
    const removeAction = useCallback(async () => {
        removeResearchItem(researchItem.id);
        const analysisResult =
            researchItem.analystRequestId && (await getTopLevelAnalysisResult(researchItem.analystRequestId));
        trackManualEvent({
            eventType: eventTypes.REMOVE_RESEARCH_ITEM,
            trackingProperties: {
                ...trackingProperties,
                theme: (analysisResult as AnalystThemeResult)?.theme,
            } as ResearchTrackingProperties,
        });
    }, [
        eventTypes.REMOVE_RESEARCH_ITEM,
        removeResearchItem,
        researchItem.analystRequestId,
        researchItem.id,
        trackManualEvent,
        trackingProperties,
    ]);
    const shareAction = async () => {
        const analysisResult =
            researchItem.analystRequestId && (await getTopLevelAnalysisResult(researchItem.analystRequestId));

        setIsShareModalOpenState(true);
        trackManualEvent({
            eventType: eventTypes.SHARE_RESEARCH_NOTE,
            trackingProperties: {
                ...trackingProperties,
                theme: (analysisResult as AnalystThemeResult)?.theme,
            } as ResearchTrackingProperties,
        });
    };

    return (
        <div className={className}>
            <Heading
                importance={5}
                className="break-words"
            >
                {researchItem?.title}
            </Heading>

            <hr className="border-analyst-dark-lavender my-3" />

            <div className="flex items-center flex-wrap gap-y-4 gap-x-10 mb-10">
                {researchItem?.updatedAt && (
                    <Paragraph className="w-full mb-0 ml-auto text-analyst-gray text-sm">
                        <strong className="font-brand-md text-black">Last updated:</strong>{' '}
                        {FormatUtcDate(new Date(researchItem.updatedAt), 'MMM d, yyyy h:mm a')}
                    </Paragraph>
                )}

                {canEdit && (
                    <ActionLink
                        onClick={editAction}
                        className="flex items-center gap-2 font-brand-md text-analyst-blue hover:cursor-pointer"
                    >
                        <Icon
                            type="edit"
                            className="text-analyst-blue group-hover:text-analyst-dark-lavender transition-colors w-3.5 h-3.5 flex items-center justify-center"
                        />
                        <span>Edit</span>
                    </ActionLink>
                )}

                <ActionLink
                    onClick={shareAction}
                    className="flex items-center gap-2 font-brand-md text-analyst-blue hover:cursor-pointer"
                >
                    <Icon
                        type="shareArrowNew"
                        className="text-analyst-blue group-hover:text-analyst-dark-lavender transition-colors w-3 h-3 flex items-center justify-center"
                    />
                    <span>Share</span>
                </ActionLink>

                {canEdit && (
                    <ActionLink
                        onClick={removeAction}
                        className="group flex items-center gap-2 font-brand-md text-brand-danger hover:cursor-pointer"
                    >
                        <Icon
                            type="delete"
                            className="text-brand-danger w-2.5 h-2.5 group-hover:text-analyst-dark-lavender transition-colors flex items-center justify-center"
                        />
                        <span>Delete</span>
                    </ActionLink>
                )}
            </div>

            <div className="flex flex-col gap-2 mb-10">
                <strong className="font-brand-md text-black">Page source:</strong>

                <NavigationLink
                    href={researchItem.url}
                    className="font-brand-md"
                >
                    {researchItem.pageName}
                </NavigationLink>
            </div>

            <div className="flex flex-col gap-2">
                <strong className="font-brand-md text-black">Notes:</strong>

                {researchItem?.note && (
                    <Paragraph className="text-analyst-gray font-brand-md">{researchItem.note}</Paragraph>
                )}
            </div>

            <ShareModal
                gtagCategory="research"
                useCurrentUrl={false}
                closeFn={() => setIsShareModalOpenState(false)}
                link={getResearchNoteShareUrl({ pageSourceUrl: researchItem.url, researchNoteId: researchItem.id })}
                isOpen={isShareModalOpen}
            />
        </div>
    );
};
