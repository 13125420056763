export enum Roles {
    Admin = 'admin',
    Analyst = 'analyst',
}

export const AnalystRoleOption = { description: 'View only', label: 'Member', value: Roles.Analyst };
export const AdminOption = {
    description: 'Create, edit, share, and manage the team',
    label: 'Admin',
    value: Roles.Admin,
};
export const RoleOptions = [AdminOption, AnalystRoleOption];
