'use client';

import { Heading, Paragraph } from '@/components/dom/text-elements';
import { ActionLink } from '@/components/global/link';
import ReadMore from '@/components/global/read-more';
import { useResearch } from '@/components/providers/Research';
import { FormatUtcDate } from '@/helpers/dates';
import { usePosthogTracking } from '@/helpers/hooks/usePosthogTracking';
import { constructCurrentResearchNoteState } from '@/helpers/research';
import { AnalystThemeResult, ResearchNote } from '@/queries/graphql-types';
import { getTopLevelAnalysisResult } from '@/services/analysis';

interface CardQuickViewProps {
    researchData: ResearchNote;
}

export const CardQuickView = ({ researchData }: CardQuickViewProps) => {
    const { eventTypes, trackManualEvent } = usePosthogTracking();
    const { switchToDetailView } = useResearch();
    const viewResearchNote = async () => {
        const analysisResult =
            researchData.analystRequestId && (await getTopLevelAnalysisResult(researchData.analystRequestId));
        const newCurrentResearchNote = await constructCurrentResearchNoteState(researchData);

        trackManualEvent({
            eventType: eventTypes.VIEW_RESEARCH_ITEM_DETAILS,
            trackingProperties: {
                ...researchData,
                exchange: researchData?.instrument?.exchange || researchData?.evaluation?.instrument?.exchange,
                indexName: researchData?.index?.name,
                indexSymbol: researchData?.index?.symbol,
                theme: (analysisResult as AnalystThemeResult)?.theme,
                ticker: researchData?.instrument?.symbol || researchData?.evaluation?.instrument?.symbol,
            },
        });

        switchToDetailView(newCurrentResearchNote);
    };

    return (
        <ActionLink
            onClick={viewResearchNote}
            className="group border border-analyst-lavender rounded-md p-4 mb-4 hover:shadow-md transition-all bg-white hover:cursor-pointer hover:border-analyst-blue text-analyst-dark-lavender"
        >
            <Heading
                importance={6}
                className="group-hover:text-analyst-blue text-black transition-colors mb-2 break-words"
            >
                {researchData.title}
            </Heading>

            {researchData.updatedAt && (
                <Paragraph className="text-analyst-gray text-sm flex items-center gap-2">
                    <span className="font-brand-md text-black">Last updated:</span>
                    {FormatUtcDate(new Date(researchData.updatedAt), 'MMM d, yyyy h:mm a')}
                </Paragraph>
            )}

            <div className="flex flex-col gap-2">
                <strong className="font-brand-md text-black">Notes:</strong>

                {researchData.note && (
                    <ReadMore
                        className="text-analyst-gray font-brand-md"
                        text={researchData.note}
                        lines={5}
                        useLink={false}
                    />
                )}
            </div>
        </ActionLink>
    );
};
